import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {Observable} from 'rxjs';

import { AppRule } from '../models/app-rule';
import { ConditionSalesforce } from '../models/condition.salesforce';
import { AuthService } from './auth.service';
import { HandleCallbackCoreRequest } from './handleCallbackCoreRequest';
import { SalesforceCredentialsRequest } from './salesforce-credentials-request';
import {SalesforceConnectAppInfo} from '../models/connect-app-info.salesforce';
import {Router} from '@angular/router';
import {SalesforceRule} from '../models/salesforce-rule';

@Injectable({
  providedIn: 'root',
})
export class SalesforceService {
  private USER_SALESFORCE_CALLBACK_URL = '/sign-in/user/salesforce';
  private TECH_SALESFORCE_CALLBACK_URL = '/sign-in/technical/salesforce';

  constructor(public authService: AuthService, private http: HttpClient, private router: Router, @Inject('env') private env: any) {}

  auth(clientId: string, domain: string, redirectUri: string) {
    window.location.replace(
      'https://' +
        domain +
        '.my.salesforce.com/services/oauth2/authorize?\n' +
        'client_id=' +
        clientId +
        '\n' +
        '&redirect_uri=' +
        redirectUri +
        '\n&' +
        'response_type=code'
    );
  }

  getSalesforceConnectAppInfo(): Observable<SalesforceConnectAppInfo> {
    const url = this.env.apiUrl + this.env.salesforceConfigEndpoint;
    return this.http.get<SalesforceConnectAppInfo>(url);
  }

  saveSalesforceCredentials(clientId: string, clientSecret: string, domain: string) {
    const url = this.env.apiUrl + this.env.salesforceLoginSaveCredentialsMethod;
    const body: SalesforceCredentialsRequest = {
      domain: domain,
      clientId: clientId,
      clientSecret: clientSecret,
    };
    return this.http.post<string>(url, body);
  }

  userManageCallback(authCode: string, callbackUri: string): Observable<{ accessToken: string }> {
    return this.manageCallback(authCode, callbackUri, this.env.salesforceUserSignIn);
  }
  techManageCallback(authCode: string, callbackUri: string): Observable<any> {
    return this.manageCallback(authCode, callbackUri, this.env.salesforceTechSignIn);
  }

  manageCallback(authCode: string, callbackUri: string, urlPath: string): Observable<any> {
    const url = this.env.apiUrl + urlPath;
    const body: HandleCallbackCoreRequest = {
      authCode: authCode,
      callbackUri: callbackUri,
    };
    return this.http.post<any>(url, body);
  }

  listRules(): Observable<AppRule[]> {
    const url = this.env.apiUrl + this.env.listAppRules;
    return this.http.get<AppRule[]>(url);
  }

  getObjectFields(object: string): Observable<ConditionSalesforce[]> {
    const url = this.env.apiUrl + this.env.getObjectFields;
    const options = {
      params: new HttpParams().set('objectName', object)
    };
    return this.http.get<ConditionSalesforce[]>(url, options);
  }

  getRule(id: string): Observable<any> {
    const url = this.env.apiUrl + this.env.getRule.replace('{ruleId}', id);
    return this.http.get<any>(url);
  }

  create(object: SalesforceRule): Observable<any> {
    const url = this.env.apiUrl + this.env.saveRule;
    return this.http.post<any>(url, object);
  }

  update(object: SalesforceRule): Observable<any> {
    const url = this.env.apiUrl + this.env.updateRule.replace('{ruleId}', object.id);
    return this.http.put<any>(url, object);
  }

  delete(id: string): Observable<any> {
    const url = this.env.apiUrl + this.env.deleteRule.replace('{ruleId}', id);
    return this.http.delete<any>(url);
  }

  getSalesforceCallbackUrl(): string {
    return this.env.apiUrl + this.USER_SALESFORCE_CALLBACK_URL;
  }

  getTechSalesforceCallbackUrl(): string {
    return this.env.apiUrl + this.TECH_SALESFORCE_CALLBACK_URL;
  }

  handleSalesforceTokenExpired(): void {
    localStorage.removeItem('sf-token');
    this.router.navigate(['/', 'sign-in', 'user', 'salesforce']);
  }
}
