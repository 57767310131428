import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {

  constructor(private router: Router) {
  }

  errorMessage$ = new Subject<string>();

  refreshAuthComponent(): void {
    let currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }

  isAuthError(error: HttpErrorResponse): boolean {
    return error.status === 401 || error.status === 403
  }
}
