import {Injectable, NgZone} from '@angular/core';
import {CanActivate, Router, UrlTree} from '@angular/router';
import {catchError, map, Observable, of} from 'rxjs';

import {UserService} from '../services/user.service';
import {AuthUserService} from '../services/auth-user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public userAuthService: AuthUserService, public userService: UserService, public router: Router, public ngZone: NgZone) {}

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.userAuthService.isSignedInAODocs() || !this.userAuthService.isSignedInSalesforce()) {
      this.router.navigate(['sign-in', 'instructions']);
    }
    return this.userService.checkUser().pipe(
      map((response) => {
        if (!response.success) {
          this.userAuthService.handleAODocsTokenExpired(response.errorMessage);
        }
        return response.success;
      }), catchError((error) => {
        console.error(error);
        this.userAuthService.handleAODocsTokenExpired(undefined);
        return of(false);
      }));
  }
}
