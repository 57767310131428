import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/guard/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'sign-in/user', pathMatch: 'full' },

  {
    path: 'sign-in/user',
    loadChildren: () => import('./sign-in/user-sign-in/user-sign-in.module').then((m) => m.UserSignInModule),
  },
  {
    path: 'sign-in/technical',
    loadChildren: () => import('./sign-in/tech-sign-in/tech-sign-in.module').then((m) => m.TechSignInModule),
  },
  {
    path: 'migration-rules',
    loadChildren: () => import('./migration-rules/migration-rules.module').then((m) => m.MigrationRulesModule),
    canActivate: [AuthGuard],
  },
  { path: '**', redirectTo: 'sign-in/user' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
