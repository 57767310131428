import { Component } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { switchMap } from 'rxjs';

import { ErrorService } from './shared/services/error.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'frontend';

  constructor(public translate: TranslateService, private _snackBar: MatSnackBar, private errorService: ErrorService) {
    translate.addLangs(['en']);
    translate.setDefaultLang('en');
    errorService.errorMessage$.pipe(switchMap((error) => this.translate.get(error))).subscribe((message) => this.openSnackBar(message));
  }

  openSnackBar(message: string) {
    this._snackBar.open(message, 'Ok', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
      panelClass: ['alert-error'],
    });
  }
}
