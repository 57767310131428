import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {mergeMap, Observable} from 'rxjs';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  private baseUrl = this.env.apiUrl

  constructor(private auth: AngularFireAuth,
              @Inject('env') public env: any) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = request.clone({
      setParams: {domain: localStorage['domain']},
    });
    if (!!localStorage['gsi-token']) {
      request = RequestInterceptor.addAuthTokenToRequest(request, localStorage['gsi-token']);
      request = this.handleSalesforceToken(request);
      return next.handle(request);
    }
    return this.auth.idToken.pipe(
      mergeMap((token: any) => {
        if (token) {
          request = RequestInterceptor.addAuthTokenToRequest(request, token);
          request = this.handleSalesforceToken(request);
        }
        return next.handle(request);
      })
    );
  }

  private static addAuthTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {Authorization: `Bearer ${token}`}
    });
  }

  private static addSalesforceTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {'Salesforce-Authorization': `${token}`}
    });
  }

  private handleSalesforceToken(request: HttpRequest<any>): HttpRequest<any> {
    if (this.checkIfSalesforceUrl(request.url)) {
      request = RequestInterceptor.addSalesforceTokenToRequest(request, localStorage['sf-token']);
    }
    return request;
  }

  private checkIfSalesforceUrl(url: string): boolean {
    const cleanedUrl = url.replace(this.baseUrl, '');
    return cleanedUrl === this.env.getObjectFields
  }
}
