import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import firebase from 'firebase/compat';
import { map, Observable } from 'rxjs';

import { AuthResponse } from './authResponse';

import UserCredential = firebase.auth.UserCredential;

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient, @Inject('env') private env: any) {}

  userGoogleSignIn(authorizationCode: string): Observable<string> {
   return this.googleSignIn(authorizationCode, this.env.googleUserSignIn);
  }

  techGoogleSignIn(authorizationCode: string): Observable<string> {
    return this.googleSignIn(authorizationCode, this.env.googleTechSignIn);
  }

  private googleSignIn(authorizationCode: string, urlPath: string): Observable<string> {
    const url = this.env.apiUrl + urlPath;

    const params = new HttpParams().set('authorizationCode', authorizationCode);
    const options = {params};
    return this.http.post<{ accessToken: string }>(url, {}, options).pipe(map((rep) => rep.accessToken));
  }

  saveFirebaseCredentials(credentials: UserCredential): Observable<AuthResponse> {
    const url = this.env.apiUrl + this.env.firebaseTechSignIn;
    return this.http.post<AuthResponse>(url, credentials);
  }

  retrieveGoogleUserInfo() {
    return this.http.get<any>('https://openidconnect.googleapis.com/v1/userinfo');
  }

  checkUser(): Observable<AuthResponse> {
    const url = this.env.apiUrl + this.env.userCheckEndpoint;
    return this.http.get<AuthResponse>(url);
  }
}
