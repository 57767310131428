import {HttpClient, HttpParams} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Router} from '@angular/router';
import firebase from 'firebase/compat/app';
import {catchError, firstValueFrom, map, Observable, of, Subject} from 'rxjs';

import {Scope} from '../models/scopes';
import {ErrorService} from './error.service';
import {UserService} from './user.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authErrorMessage$ = new Subject<string>();
  scopes = [Scope.EMAIL, Scope.PROFILE, Scope.OPENID].join(' ');

  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    public userService: UserService,
    public http: HttpClient,
    public router: Router,
    public errorService: ErrorService,
    @Inject('env') public env: any
  ) {
    this.authErrorMessage$.asObservable().subscribe((error) => this.errorService.errorMessage$.next(error));
  }

  async setTenantIdInAuthInstance(domain: string): Promise<void> {
    let response = await firstValueFrom(this.getTenantId(domain));
    firebase.auth().tenantId = response.tenantId;
  }

  async signOut() {
    localStorage.removeItem('domain');
    localStorage.removeItem('gsi-token');
    localStorage.removeItem('sf-token');
    await this.afAuth.signOut().then(async () => {
      await this.router.navigate(['/', 'sign-in', 'user', 'instructions']);
    });
  }

  getTenantId(domain: string): Observable<any> {
    const url = this.env.apiUrl + this.env.tenantIdEndpoint;
    const options = {
      params: new HttpParams().set('domain', domain),
    };
    return this.http.get<any>(url, options);
  }

  isSignedInAODocs(): Observable<boolean> {
    return this.userService.checkUser().pipe(
      map((checkUser) => checkUser.success),
      catchError(() => {
        localStorage.removeItem('gsi-token');
        return of(false)
      })
    );
  }

  handleAODocsGoogleAuthError(error: string): void {
    localStorage.removeItem('gsi-token');
    this.authErrorMessage$.next(error);
    this.errorService.refreshAuthComponent();
  }
}
